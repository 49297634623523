@import '~antd/dist/antd.css';
body{
    background-color: #f6f6f6;  
  }

.comm-box{
    background-color: #FFF;
    margin-left: 1rem;
    margin-right: 1rem;
    padding:.3rem;
    border-radius: .3rem;
    border:1px solid #eee;
    align-content: center;
    
  }
  .liy{
    margin-left: .3rem;
  }
 